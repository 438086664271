html,
body {
    max-height: 100%;
    margin: 0 auto;
    background-color: #fff;

    @media (prefers-color-scheme: dark) {
        background-color: #202b38;
    }
}

body {
    padding-bottom: 20px;
}
